import classnames from 'classnames'
import { isValidFontFamily } from 'components/email-layout/constants'
import { isNil } from 'lodash'
import React, { useState } from 'react'
import Select from 'react-select'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap'
import './_styles.scss'
import * as TypeAhead from "react-bootstrap-typeahead";

export type DropdownKey = number | string

export interface DropdownOption {
  context?: any,
  description?: string,
  divider?: boolean,
  key: DropdownKey,
  value?: any,
}

export interface OnChangeEvent {
  selected: DropdownOption
}

export type OnChange = (e: OnChangeEvent) => void

// This overriding of types OFFENDS ME GREATLY.
interface DropdownProps {
  className?: string,
  data: DropdownOption[],
  disabled?: boolean,
  onChange: OnChange,
  selected?: DropdownKey,
}

export const DropdownComponent = ({className, data, disabled, onChange, selected}: DropdownProps) => {
  return <Input disabled={disabled}
                className={classnames('dropdown-component', className || '')}
                type="select"
                value={isNil(selected) ? data[0].key : selected}
                onChange={
                      (e) => {
                        // eslint-disable-next-line eqeqeq
                        const selectedOption = data.find((d: DropdownOption) => d.key == e.target.value && !d.divider)
                        if (selectedOption) {
                          onChange({selected: selectedOption})
                        }
                      }
                }>
    {data.map((d, i) =>
      <option key={`dropdown-key-${d.key}`} value={d.key} className={d.divider ? 'divider' : 'option'}>
        {d.description || d.key}
      </option>)}
  </Input>
}

export interface Item {
  label?: string,
  type?: string,
  description?: string,
}

export const DropdownWithDividersComponent = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  return (
    <div className="dropdown-with-dividers">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret={true}
          style={{
            fontFamily: props.selected ? props.selected.value : 'inherit',
          }}>
          {props.selected && props.selected.name ? props.selected.name : 'SELECT'}
        </DropdownToggle>
        <DropdownMenu
          modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: (data) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: 'auto',
                  maxHeight: '200px',
                },
              }
            },
          }}}
        >
          {(props.data).map((item, i) => {
            return (
            <DropdownItem header={item.category === 'header'}
                          divider={item.category === 'divider'}
                          key={i}
                          onClick={props.onChange}
                          value={item.value}
                          data-type={item.type}
                          style={{fontFamily: isValidFontFamily(item.name) ? item.value : 'inherit'}}>
              {item.name}
            </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export const MultiSelectDropdown = (props) => (
  <Select
    placeholder={props.placeholder}
    className={props.className || ''}
    defaultValue={props.defaultValue || []}
    value={props.selected || []}
    isMulti
    options={props.options}
    onChange={props.onSelect}
  />
)

export const FilterDropdown = (props) => (
  <TypeAhead.Typeahead
    filterBy={props.filterByCallback}
    placeholder={props.placeholder}
    options={props.options}
    onChange={props.onSelect}
    renderMenuItemChildren={props.items}
  />
)
